import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay]);

const swiperSelectors = ['.other-cases-swiper', '.cases-swiper'];
const swipersList = [];

let swiperConfig = {
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  spaceBetween: 30,
  resizeObserver: true,
};

const navigationConfig = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

const brandsConfig = {
  slidesPerView: 6,
  spaceBetween: 30,
  resizeObserver: true,
  loop: true,
  loopFillGroupWithBlank: true,
  breakpoints: {
    320: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    667: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    932: {
      slidesPerView: 6,
      spaceBetween: 30,
    },
  },
};

swiperSelectors.forEach((selector) => {
  swipersList.push(new Swiper(selector, Object.assign({}, swiperConfig, navigationConfig)));
});
const brandCarousel = new Swiper('.brand_section-container', brandsConfig);
swipersList.push(brandCarousel);

const initAutoPlay = (carousel, parentClass, autoplayDelay = 3000) => {
  const otherCasesSlider = document.querySelector('.other-cases-swiper');

  if (carousel.autoplay.running || otherCasesSlider) {
    return;
  }

  let current = carousel.wrapperEl;

  if (current) {
    while (!current.classList.contains(parentClass)) {
      current = current.parentNode;
    }
    const bounding = current.getBoundingClientRect();

    const screenHeight = window.innerHeight / 2;

    if (bounding.top < screenHeight) {
      carousel.autoplay.start();
      carousel.params.autoplay.delay = autoplayDelay;
      carousel.params.autoplay.disableOnInteraction = false;
    }
  }
};

function init() {
  initAutoPlay(brandCarousel, 'brand_section');
}

window.addEventListener('scroll', init);

init();

export default swipersList;
